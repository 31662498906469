<template lang="pug">
  div
    .user-manage
      .container
        .row.justify-content-md-center
          .col-lg-5
            router-link.btn.btn-secondary.float-right(to="/register_user/send_auth") 新規ユーザー登録
            h1.pt-5 ログイン
            .alert.alert-danger(v-if="message") {{ message }}
            .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
              div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
                router-link(to="/inquiry_form/input") こちら
                |よりをお問い合わせください。
            form.needs-validation(@submit.prevent="exec" novalidate)
              .form-group
                input.form-control(type="text" placeholder="ユーザーIDまたはメールアドレス" v-model="mail" required)
                .invalid-feedback ユーザーIDまたはメールアドレスが正しく入力されていません
              .form-group
                input.form-control.mt-4(type="password" minlength='8' maxlength='16' placeholder="パスワード" v-model="password" required)
                .invalid-feedback パスワード(8文字以上16文字以内)が正しく入力されていません
              .form-check.form-check.my-3
                input#memory.form-check-input(type="checkbox" v-model="memory")
                label.form-check-label(for="memory") ログイン情報を記憶する
              input.btn.btn-primary.col-4.my-4(type="submit" value="ログイン" v-bind:disabled="isDisabled")
              br
              router-link(to="/user/password_reset/reset") パスワードお忘れの場合

</template>

<script>
import {
    Config,
    CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      message: '',
      mail: '',
      password: '',
      memory: true,
      isDisabled: false,
      errMessage: false,
    }
  },
  created() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)
  },
  mounted() {
    if (this.$user.cognito) {
      this.$router.push('/')
      return
    }
    if (localStorage.mail) {
      this.mail = localStorage.mail
    }
  },
  methods: {
    //全角を半角英数字に変更処理
    toHalfWidth(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ０-９．＠＋－]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    },
    //ログイン処理
    exec(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      //エラー処理
      if(e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
        this.isDisabled = false
        return
      }

      e.target.classList.remove('was-validated')

      this.mail = this.mail.trim()

      this.mail = this.toHalfWidth(this.mail)

      let authenticationData = {
        Username: this.mail,
        Password: this.password
      }

      let authenticationDetails = new AuthenticationDetails(authenticationData)//インスタンスの生成
      
      let userData = {
        Username : this.mail.toLowerCase(),
        Pool : this.userPool,
        Storage: (this.memory ? localStorage : sessionStorage)
      }
      this.$user.cognito = new CognitoUser(userData)

      this.$user.cognito.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          // 処理結果Ok
          localStorage.mail = this.mail
          let url = this.$route.query.url
          this.$router.push(url ? url.replace('/logout', '/') : '/')
        },
        onFailure: (err) => {
          // 処理結果Error
          if(err.code == 'InvalidParameterException') {
            this.message = 'メールアドレスとパスワードを入力してください'
          }else if(err.code == 'NotAuthorizedException' || !err.code) {
            this.message = 'ユーザーネームまたはパスワードが違います'
          }else if(err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else if(err.code == 'UserNotConfirmedException') {
            //ページ遷移
            this.$router.push({ name:'register_user_input_auth' , params: { mail: this.mail, password: this.password}})
            return
          }else {
            console.log(err)
            this.errMessage = true
          }
          this.isDisabled = false
          return
        }
      })  
    }
  }
}
</script>

<style lang="stylus" scoped>
#app
  .user-manage
    min-height 100vh
    background-attachment fixed
    background-size cover
    background-position center
    background-color #171d32
    // opacity .9
    text-align center
    .container
      margin-top 0px
      margin-bottom 0px
      padding-top 75px
    h1, .form-check-label
      color #fff
  #footer-wrap
    bottom 0
    position fixed
    width 100%
</style>