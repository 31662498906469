var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "user-manage" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-md-center" }, [
          _c(
            "div",
            { staticClass: "col-lg-5" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-secondary float-right",
                  attrs: { to: "/register_user/send_auth" }
                },
                [_vm._v("新規ユーザー登録")]
              ),
              _c("h1", { staticClass: "pt-5" }, [_vm._v("ログイン")]),
              _vm.message
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                : _vm._e(),
              _vm.errMessage
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v("システムエラーが発生しました。"),
                    _c(
                      "div",
                      [
                        _vm._v(
                          "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: "/inquiry_form/input" } },
                          [_vm._v("こちら")]
                        ),
                        _vm._v("よりをお問い合わせください。")
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.exec($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mail,
                          expression: "mail"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "ユーザーIDまたはメールアドレス",
                        required: ""
                      },
                      domProps: { value: _vm.mail },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.mail = $event.target.value
                        }
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "ユーザーIDまたはメールアドレスが正しく入力されていません"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-control mt-4",
                      attrs: {
                        type: "password",
                        minlength: "8",
                        maxlength: "16",
                        placeholder: "パスワード",
                        required: ""
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "パスワード(8文字以上16文字以内)が正しく入力されていません"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-check form-check my-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memory,
                          expression: "memory"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "memory", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.memory)
                          ? _vm._i(_vm.memory, null) > -1
                          : _vm.memory
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.memory,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.memory = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.memory = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.memory = $$c
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "memory" }
                      },
                      [_vm._v("ログイン情報を記憶する")]
                    )
                  ]),
                  _c("input", {
                    staticClass: "btn btn-primary col-4 my-4",
                    attrs: {
                      type: "submit",
                      value: "ログイン",
                      disabled: _vm.isDisabled
                    }
                  }),
                  _c("br"),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/password_reset/reset" } },
                    [_vm._v("パスワードお忘れの場合")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }